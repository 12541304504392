/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { Resource } from '../models/resource';
import { ProjectCategory } from '../models/project-category';
import { Project } from '../models/project';
import { ContactUs } from '../models/contact-us';
import { ServiceCategory } from '../models/service-category';
import { ServiceDetails } from '../models/service-details';
import { ServiceView } from '../models/service-view';
@Injectable({
  providedIn: 'root',
})
class PublicService extends __BaseService {
  static readonly getPublicImage1Path = '/api/public/assets/image/{id}';
  static readonly getPublicProjectCategories1Path = '/api/public/project-categories';
  static readonly getPublicProject1Path = '/api/public/project/{projectId}';
  static readonly getPublicProjects1Path = '/api/public/projects';
  static readonly sendMail1Path = '/api/public/send-email';
  static readonly getPublicServiceCategories1Path = '/api/public/service-categories';
  static readonly getService1Path = '/api/public/service/{serviceId}';
  static readonly getServices1Path = '/api/public/services';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Your GET endpoint
   *
   * Get Public Image
   * @param id id
   * @return OK
   */
  getPublicImage1Response(id: string): __Observable<__StrictHttpResponse<Resource>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/public/assets/image/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Resource>;
      })
    );
  }
  /**
   * Your GET endpoint
   *
   * Get Public Image
   * @param id id
   * @return OK
   */
  getPublicImage1(id: string): __Observable<Resource> {
    return this.getPublicImage1Response(id).pipe(
      __map(_r => _r.body as Resource)
    );
  }

  /**
   * Get All Project Categories
   *
   * Get All Project Categories
   * @param lang lang
   * @return OK
   */
  getPublicProjectCategories1Response(lang?: string): __Observable<__StrictHttpResponse<Array<ProjectCategory>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (lang != null) __params = __params.set('lang', lang.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/public/project-categories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ProjectCategory>>;
      })
    );
  }
  /**
   * Get All Project Categories
   *
   * Get All Project Categories
   * @param lang lang
   * @return OK
   */
  getPublicProjectCategories1(lang?: string): __Observable<Array<ProjectCategory>> {
    return this.getPublicProjectCategories1Response(lang).pipe(
      __map(_r => _r.body as Array<ProjectCategory>)
    );
  }

  /**
   * Get Project by ID
   *
   * Get Project by ID
   * @param projectId projectId
   * @return OK
   */
  getPublicProject1Response(projectId: string): __Observable<__StrictHttpResponse<Project>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/public/project/${encodeURIComponent(String(projectId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Project>;
      })
    );
  }
  /**
   * Get Project by ID
   *
   * Get Project by ID
   * @param projectId projectId
   * @return OK
   */
  getPublicProject1(projectId: string): __Observable<Project> {
    return this.getPublicProject1Response(projectId).pipe(
      __map(_r => _r.body as Project)
    );
  }

  /**
   * Your GET endpoint
   * @param params The `PublicService.GetPublicProjects1Params` containing the following parameters:
   *
   * - `size`: size
   *
   * - `search`: search
   *
   * - `page`: page
   *
   * - `categoryId`: categoryId
   *
   * @return OK
   */
  getPublicProjects1Response(params: PublicService.GetPublicProjects1Params): __Observable<__StrictHttpResponse<Array<Project>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.size != null) __params = __params.set('size', params.size.toString());
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.categoryId != null) __params = __params.set('categoryId', params.categoryId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/public/projects`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Project>>;
      })
    );
  }
  /**
   * Your GET endpoint
   * @param params The `PublicService.GetPublicProjects1Params` containing the following parameters:
   *
   * - `size`: size
   *
   * - `search`: search
   *
   * - `page`: page
   *
   * - `categoryId`: categoryId
   *
   * @return OK
   */
  getPublicProjects1(params: PublicService.GetPublicProjects1Params): __Observable<Array<Project>> {
    return this.getPublicProjects1Response(params).pipe(
      __map(_r => _r.body as Array<Project>)
    );
  }

  /**
   * Send Email
   *
   * Send Email from Contact Us Form
   * @param contactUs contactUs
   */
  sendMail1Response(contactUs?: ContactUs): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = contactUs;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/public/send-email`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Send Email
   *
   * Send Email from Contact Us Form
   * @param contactUs contactUs
   */
  sendMail1(contactUs?: ContactUs): __Observable<null> {
    return this.sendMail1Response(contactUs).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get All Service Categories
   *
   * Get All Service Categories
   * @return OK
   */
  getPublicServiceCategories1Response(): __Observable<__StrictHttpResponse<Array<ServiceCategory>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/public/service-categories`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ServiceCategory>>;
      })
    );
  }
  /**
   * Get All Service Categories
   *
   * Get All Service Categories
   * @return OK
   */
  getPublicServiceCategories1(): __Observable<Array<ServiceCategory>> {
    return this.getPublicServiceCategories1Response().pipe(
      __map(_r => _r.body as Array<ServiceCategory>)
    );
  }

  /**
   * Get Service by Service ID
   *
   * Get Service by Service ID
   * @param serviceId serviceId
   * @return OK
   */
  getService1Response(serviceId: string): __Observable<__StrictHttpResponse<ServiceDetails>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/public/service/${encodeURIComponent(String(serviceId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ServiceDetails>;
      })
    );
  }
  /**
   * Get Service by Service ID
   *
   * Get Service by Service ID
   * @param serviceId serviceId
   * @return OK
   */
  getService1(serviceId: string): __Observable<ServiceDetails> {
    return this.getService1Response(serviceId).pipe(
      __map(_r => _r.body as ServiceDetails)
    );
  }

  /**
   * Get All Services
   *
   * Get All Services
   * @param params The `PublicService.GetServices1Params` containing the following parameters:
   *
   * - `search`: service name
   *
   * - `pageSize`: pageSize
   *
   * - `page`: page
   *
   * - `category`: category id to use for getting services
   *
   * @return OK
   */
  getServices1Response(params: PublicService.GetServices1Params): __Observable<__StrictHttpResponse<Array<ServiceView>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.search != null) __params = __params.set('search', params.search.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.page != null) __params = __params.set('page', params.page.toString());
    if (params.category != null) __params = __params.set('category', params.category.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/public/services`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<ServiceView>>;
      })
    );
  }
  /**
   * Get All Services
   *
   * Get All Services
   * @param params The `PublicService.GetServices1Params` containing the following parameters:
   *
   * - `search`: service name
   *
   * - `pageSize`: pageSize
   *
   * - `page`: page
   *
   * - `category`: category id to use for getting services
   *
   * @return OK
   */
  getServices1(params: PublicService.GetServices1Params): __Observable<Array<ServiceView>> {
    return this.getServices1Response(params).pipe(
      __map(_r => _r.body as Array<ServiceView>)
    );
  }
}

module PublicService {

  /**
   * Parameters for getPublicProjects1
   */
  export interface GetPublicProjects1Params {

    /**
     * size
     */
    size?: number;

    /**
     * search
     */
    search?: string;

    /**
     * page
     */
    page?: number;

    /**
     * categoryId
     */
    categoryId?: string;
  }

  /**
   * Parameters for getServices1
   */
  export interface GetServices1Params {

    /**
     * service name
     */
    search?: string;

    /**
     * pageSize
     */
    pageSize?: number;

    /**
     * page
     */
    page?: number;

    /**
     * category id to use for getting services
     */
    category?: string;
  }
}

export { PublicService }
