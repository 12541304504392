import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { filter, map } from 'rxjs/operators';
import { ProjectName } from './app-constants';
import { EventService } from './core/services/event.service';
import { LanguageService } from './core/services/language.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit{
  currentLang = 'en';
  title: any = {
    en : '',
    ar : ''
  };
  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private titleService: Title,
              private langService: LanguageService,
              private eventService: EventService,
              private localStorageService: LocalStorageService,
              ){}


  ngOnInit(): void{
    this.currentLang = this.langService.translate.currentLang;
    this.eventService.subscribe('lang', (lang) => {
    this.currentLang = lang.lang;
    this.setItNow();
  });

    this.setTitle();

  }
  ngAfterViewInit(): void{
    this.addPageScripts();
  }
  addPageScripts(): void{
    if (document.getElementById('themeScript') && document.getElementById('themeInitScript')){
      document.getElementById('themeScript').remove();
      document.getElementById('themeInitScript').remove();
    }
    const node = document.createElement('script');
    node.src = './assets/js/theme.js';
    node.type = 'text/javascript';
    node.id = 'themeScript';
    document.body.appendChild(node);
    const node2 = document.createElement('script');
    node2.src = './assets/js/theme.init.js';
    node2.type = 'text/javascript';
    node2.id = 'themeInitScript';
    document.body.appendChild(node2);
  }
  setTitle(): void{
    const appTitle = this.titleService.getTitle();
    this.router
      .events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          this.addPageScripts();
          let child = this.activatedRoute.firstChild;
          while (child.firstChild) {
            child = child.firstChild;
          }
          if (child.snapshot.data.title) {
            return child.snapshot.data.title;
          }
          return appTitle;
        })
      ).subscribe((ttl: any) => {
        if (ttl.en){
          this.title.en = ' - ' + ttl.en;
        }else{
          this.title.en = '';
        }
        if (ttl.ar){
          this.title.ar = ' - ' +  ttl.ar;
        }else{
          this.title.ar = '';
        }
        this.setItNow();
      });
  }

  setItNow(): void{
    if (this.currentLang === 'ar'){
      this.titleService.setTitle(ProjectName.ar + this.title.ar);
    }else if (this.currentLang === 'en'){
      this.titleService.setTitle(ProjectName.en + this.title.en);
    }
  }
}
