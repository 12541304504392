<footer id="footer" class="mt-0">
    <div *ngIf="false" class="container my-4">
        <div class="row py-5">
            <div class="col-md-5 col-lg-3 mb-5 mb-lg-0">
                <h5 class="text-6 text-transform-none font-weight-semibold text-color-light mb-4">{{'footer.contactDetails' | translate}}
                </h5>
                <h6>{{'footer.elrsaamCo' | translate}}</h6>

                <p class="text-5 mb-1 pt-2">
                    <span>{{'footer.call' | translate}}</span>: 01124454545</p>
                <p class="text-5 mb-0">
                    <span>{{'footer.email' | translate}}</span>: <a href="">info@elrsaam.com</a></p>
            </div>
            <div class="col-md-7 col-lg-5 mb-5 mb-lg-0">
                <h5 class="text-6 text-transform-none font-weight-semibold text-color-light mb-4">{{'footer.pages' | translate}}</h5>
                <div class="row">
                    <div class="col-6 text-capitalize">
                        <p class="mb-1"><a [routerLink]="['/']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" class="text-4 link-hover-style-1">{{'footer.home' | translate}}</a></p>
                        <p *ngIf="false" class="mb-1"><a routerLink="/services" routerLinkActive="active" class="text-4 link-hover-style-1">{{'footer.services' | translate}}</a></p>
                        <p *ngIf="false" class="mb-1"><a routerLink="/portfolio" routerLinkActive="active" class="text-4 link-hover-style-1">{{'footer.portfolio' | translate}}</a></p>
                        <p class="mb-1"><a [routerLink]="['/about-us']" routerLinkActive="active" class="text-4 link-hover-style-1">{{'footer.aboutUs' | translate}} </a></p>
                        <p class="mb-1"><a [routerLink]="['/contact-us']" routerLinkActive="active" class="text-4 link-hover-style-1">{{'footer.contactUs' | translate}}</a></p>
                    </div>

                </div>
            </div>
            <div *ngIf="false" class="col-lg-4">
                <h5 class="text-6 text-transform-none font-weight-semibold text-color-light mb-4">{{'footer.Newsletter' | translate}}</h5>
                <p class="text-4 mb-1">{{'footer.latestInfo' | translate}}</p>
                <p class="text-4">{{'footer.sifnUp' | translate}}</p>
                <div class="alert alert-success d-none" id="newsletterSuccess">
                    <strong>{{'footer.success' | translate}}</strong>
                    <span>{{'footer.emailAdd' | translate}}</span>
                </div>
                <div class="alert alert-danger d-none" id="newsletterError"></div>
                <form id="newsletterForm" class="mw-100">
                    <div class="input-group input-group-rounded">
                        <input class="form-control form-control-sm bg-light px-4 text-3" [placeholder]="'footer.emailAddress' | translate" name="newsletterEmail" id="newsletterEmail" type="text">
                        <span class="input-group-append">
                            <button class="btn btn-primary text-color-light text-2 py-3 px-4"
                                type="submit"><strong>{{'footer.subscribe' | translate}}</strong></button>
                        </span>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="footer-copyright footer-copyright-style-2">
        <div class="container py-2">
            <div class="row py-4">
                <div class="col d-flex align-items-center justify-content-center mb-4 mb-lg-0">
                    <p class="mb-0 text-light">
                        <span>{{'footer.footerBar.start' | translate}} <em class='fa fa-heart text-danger'></em> {{'footer.footerBar.by' | translate}}</span>
                        <a href="https://www.homains.eu" target="_blank" class="text-reset text-primary"> {{'footer.footerBar.homains' | translate}}</a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</footer>