<header id="header" class="header-transparent header-transparent-dark-bottom-border header-transparent-dark-bottom-border-1 header-effect-shrink" data-plugin-options="{'stickyEnabled': false, 'stickyEffect': 'shrink', 'stickyEnableOnBoxed': true, 'stickyEnableOnMobile': true, 'stickyChangeLogo': true, 'stickyStartAt': 30, 'stickyHeaderContainerHeight': 70}">
    <div class="header-body border-top-0 bg-dark box-shadow-none">
        <div class="header-container container">
            <div class="header-row">
                <div class="header-column">
                    <div class="header-row">
                        <div *ngIf="currentLang === 'en'" class="header-logo">
                            
                            <a routerLink="/" *ngIf="navClass == 'nav-light'">
                                <img alt="Elrsaam" height="30" src="../../../assets/img/logos/logo_elrsaam.png">
                            </a>
                        </div>
                        <div *ngIf="currentLang !== 'en'" class="header-logo">
                          
                            <a routerLink="/" *ngIf="navClass !== 'nav-light'">
                                <img alt="Elrsaam" height="30" src="../../../assets/img/logos/logo_ar.png">
                            </a>
                        </div>
                    </div>
                </div>
                <div class="header-column justify-content-end">
                    <div class="header-row">
                        <div class="header-nav header-nav-links header-nav-dropdowns-dark header-nav-light-text order-2 order-lg-1">
                            <div class="header-nav-main header-nav-main-mobile-dark header-nav-main-square header-nav-main-dropdown-no-borders header-nav-main-effect-2 header-nav-main-sub-effect-1">
                                <nav class="collapse">
                                    <ul class="nav nav-pills text-capitalize" id="mainNav">
                                        <li class="nav-item" data-toggle="collapse" data-target=".collapse.show">
                                            <a class="nav-link" [routerLink]="['/']" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" href="javascript:void(0)">
                                                {{'navbar.home' | translate}}
                                            </a>
                                        </li>
                                        <li  class="nav-item" data-toggle="collapse" data-target=".collapse.show">
                                            <a class="nav-link" routerLink="/services" routerLinkActive="active" href="javascript:void(0)">
                                              {{'navbar.services' | translate}}
                                          </a> 
                                        </li>
                                        <li  class="nav-item" data-toggle="collapse" data-target=".collapse.show">
                                            <a class="nav-link" routerLink="/portfolio" routerLinkActive="active" href="javascript:void(0)">
                                              {{'navbar.portfolio' | translate}}
                                          </a>
                                        </li>
                                        <li class="nav-item" data-toggle="collapse" data-target=".collapse.show">
                                            <a class="nav-link" [routerLink]="['/about-us']" routerLinkActive="active" href="javascript:void(0)">
                                                {{'navbar.about-us' | translate}}
                                            </a>
                                        </li>
                                        <li class="nav-item" data-toggle="collapse" data-target=".collapse.show">
                                            <a class="nav-link" [routerLink]="['/contact-us']" routerLinkActive="active" href="javascript:void(0)">
                                                {{'navbar.contact-us' | translate}}
                                            </a>
                                        </li>
                                        <li *ngIf="false" class="dropdown">
                                            <a class="dropdown-item dropdown-toggle" href="javascript:void(0)">
                                                <span style="margin: 10px;">{{'navbar.langs.lang' | translate}}</span>
                                            </a>
                                            <ul class="dropdown-menu">
                                                <li>
                                                    <a (click)="setLanguage('ar')" [ngClass]="{'active' : currentLang === 'ar'}" class="dropdown-item" href="javascript:void(0)">
                                                        {{'navbar.langs.ar' | translate}}
                                                    </a>
                                                </li>
                                                <li>
                                                    <a (click)="setLanguage('en')" [ngClass]="{'active' : currentLang === 'en'}" class="dropdown-item" href="javascript:void(0)">
                                                        {{'navbar.langs.en' | translate}}
                                                    </a>
                                                </li>
                                            </ul>
                                        </li>
                                        <li *ngIf="false" class="nav-item px-2 row align-items-center" data-toggle="collapse" data-target=".collapse.show">
                                            <button class="btn-sm btn btn-light" [routerLink]="['/account/login']" routerLinkActive="active">
                                                {{'navbar.login' | translate}}
                                            </button>

                                        </li>
                                        <li *ngIf="false" class="nav-item px-2 row align-items-center" data-toggle="collapse" data-target=".collapse.show">
                                            <button class="btn btn-sm btn-light" [routerLink]="['/account/login']" routerLinkActive="active">
                                                {{'navbar.register' | translate}}
                                            </button>

                                        </li>


                                    </ul>
                                </nav>
                            </div>
                            <button class="btn header-btn-collapse-nav" data-toggle="collapse" data-target=".header-nav-main nav">
                                <i class="fas fa-bars"></i>
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</header>