export enum Authority {
  ADMIN = 'ROLE_ADMIN',
  USER = 'ROLE_USER',
}
export enum Languages {
  AR = 'ar',
  EN = 'en',
}
export const ProjectName = {
ar : 'الرسام',
en : 'Elrsaam'
};
