import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EventService } from 'src/app/core/services/event.service';
import { LanguageService } from 'src/app/core/services/language.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  currentLang = 'en';
  navClass = '';
  constructor(
    private eventService: EventService,
    public languageService: LanguageService,
    public translate: TranslateService,
    ) { }

  ngOnInit(): void {
    this.currentLang = this.translate.currentLang;
    this.eventService.subscribe('lang', (p) => {
      this.currentLang = p.lang;
    });
  }

  setLanguage(lang: string): void {
    this.languageService.setLanguage(lang);
  }
}
