import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from 'ngx-webstorage';
import { EventService } from './event.service';

@Injectable({ providedIn: 'root' })
export class LanguageService {
  public languages: string[] = ['en', 'ar'];

  constructor(public translate: TranslateService, private loalStrorageService: LocalStorageService, private eventService: EventService) {
    let browserLang = 'ar';
    this.translate.addLangs(this.languages);
    // if (this.loalStrorageService.retrieve('lang')) {
    //   browserLang = this.loalStrorageService.retrieve('lang');
    // }
    // else {
    //   browserLang = translate.getBrowserLang();
    // }
    // if (browserLang === 'ar'){
    //   document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
    // }
    translate.use(browserLang.match(/en|ar/) ? browserLang : 'en');
    if (browserLang === 'ar'){
      document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
    }else{
      document.getElementsByTagName('html')[0].setAttribute('dir', 'ltr');
    }
    document.getElementsByTagName('html')[0].setAttribute('lang', browserLang);
  }

  public setLanguage(lang) {
    this.translate.use(lang);
    this.eventService.broadcast('lang', {lang});
    this.loalStrorageService.store('lang', lang);
    if (lang === 'ar'){
      document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
    }else{
      document.getElementsByTagName('html')[0].setAttribute('dir', 'ltr');
    }
    document.getElementsByTagName('html')[0].setAttribute('lang', lang);
  }

}
